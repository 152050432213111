import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {Box, Grid, Typography} from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";

import ProfileViewer from "../../../components/shared/ProfileViewer";
import CompanyViewer from "../../../components/shared/CompanyViewer";
import { Roles } from "../../../utilities/constants";
import { getCurrentUserSelector, getUserSelector } from "../../../redux/users/selector";
import { getUserById } from "../../../redux/users/actions";
import PrimaryButton from "../../../components/shared/PrimaryButton";
import UserForm from "../../../components/shared/ProfileForm";
import UserFormV2 from "../../../components/shared/ProfileFormV2";
import { getDocumentsForUser } from "../../../redux/documents/selectors";
import { getUserDocuments } from "../../../redux/documents/actions";
import DocumentsTable from "../../../components/shared/DocumentsTable";
import CustomTabs from "../../../components/shared/Tabs";
import PromoterViewer from "../../../components/shared/PromoterViewer";
import PdfProfileViewButton from "../../../components/shared/ProfileViewer/PdfProfileViewButton";

const SingleUser = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const currentUser = useSelector(getCurrentUserSelector);
  const selectedUser = useSelector(getUserSelector(params.id));
  const previousPath = useSelector((state) => state.utilities.previousPath);
  const documents = useSelector(getDocumentsForUser(selectedUser?.id));

  useEffect(() => {
    if (selectedUser && selectedUser?.role === Roles.USER.value) {
      dispatch(getUserDocuments({ userId: selectedUser.id }));
    }
  }, [selectedUser?.id]);

  useEffect(() => {
      dispatch(getUserById({ id: params.id }))
        .unwrap()
        .then((data) => {
          if (currentUser.role === Roles.COMPANY.value && data.role === Roles.COMPANY.value) {
            navigate(-1);
          }
        })
        .catch(() => {
          navigate(-1);
        });
  }, [params.id]);

  const getViewComponent = () => {
    if (selectedUser.role === Roles.USER.value) {
      return <ProfileViewer
        user={currentUser}
        profile={{ email: selectedUser.email, ...selectedUser.profile }}
      />
    }
    if (selectedUser.role === Roles.COMPANY.value) {
      return <CompanyViewer data={selectedUser.company} />
    }
    if (selectedUser.role === Roles.PROMOTER.value) {
      return <PromoterViewer data={selectedUser.promoter} />
    }
    return null;
  }

  return (
    currentUser &&
    selectedUser && (
      <Box width="80%" margin="auto">
        <CustomTabs
          tabs={[
            {
              label: "View Profile",
              value: "view-profile",
              component: getViewComponent(),
              onClick: () => navigate("?tab=view-profile"),
            },
            ...(currentUser.role === Roles.ADMIN.value || currentUser.role === Roles.PROMOTER.value
              ? [
                {
                  label: "Edit Profile",
                  value: "edit-profile",
                  component: <UserForm userId={selectedUser.id} canSkipSteps />,
                  onClick: () => navigate("?tab=edit-profile"),
                }
              ]
              : []),
            ...(currentUser.role === Roles.ADMIN.value
              ? [
                {
                  label: "Edit Profile V2",
                  value: "edit-profile-v2",
                  component: <UserFormV2 userId={selectedUser.id} canSkipSteps />,
                  onClick: () => navigate("?tab=edit-profile-v2"),
                }
              ]
              : []),
            ...(currentUser.role === Roles.ADMIN.value && selectedUser?.role === Roles.USER.value
              ? [
                  {
                    label: "Documents",
                    value: "documents",
                    component: <DocumentsTable userId={selectedUser.id} loading={documents?.loading} documents={documents?.data || []} />,
                    onClick: () => navigate("?tab=documents"),
                  },
                ]
              : []),
          ]}
          defaultTab="view-profile"
          header={
            <Grid container>
              {currentUser.role === Roles.USER.value && (
                <Grid item md={4} lg={4} sx={{ paddingRight: '10px' }}>
                    <Box>
                        <Typography variant="h4">
                            Verified: {selectedUser?.isAccountVerified ? <VerifiedIcon sx={{ color: "#C02026", marginLeft: "5px" }} /> : <CancelIcon sx={{ color: "#C02026", marginLeft: "5px" }} />}
                        </Typography>
                    </Box>
                </Grid>
              )}
              {(currentUser.role === Roles.ADMIN.value && selectedUser.role === Roles.USER.value) && (
                  <>
                    <Grid item md={3} lg={3} sx={{ textAlign: 'left', paddingLeft: '20px' }}>
                      <PdfProfileViewButton privacy='normal' selectedUser={selectedUser} />
                    </Grid>
                    {currentUser.role === Roles.ADMIN.value && (
                      <>
                        <Grid item md={3} lg={3} sx={{ textAlign: 'left', paddingLeft: '20px' }}>
                          <PdfProfileViewButton privacy='full' selectedUser={selectedUser} buttonText="Download Full PDF" />
                        </Grid>
                        <Grid item md={3} lg={3} sx={{ textAlign: 'left', paddingLeft: '20px' }}>
                          <PdfProfileViewButton privacy='min' selectedUser={selectedUser} buttonText="Download Min PDF" />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid item md={3} lg={3} sx={{ textAlign: 'left', paddingLeft: '20px' }}>
                    <PrimaryButton onClick={() => navigate(previousPath)}>Back</PrimaryButton>
                </Grid>
            </Grid>
          }
        />
      </Box>
    )
  );
};

export default SingleUser;
