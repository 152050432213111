import { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { Box, Grid, MobileStepper, Step, StepLabel, Stepper } from "@mui/material";

import {
  companyVerificationSchema, companyVerificationSteps,
  profileVerificationSchema, profileVerificationSteps, promoterVerificationSchema, promoterVerificationSteps
} from "./formDetails";
import { patchProfile } from "../../../redux/profile/actions";
import { patchCompany } from "../../../redux/company/actions";
import { getCurrentUser, getUserById } from "../../../redux/users/actions";
import Form from "../../form/Form";
import PrimaryButton from "../PrimaryButton";
import {
  CompanyVerificationSteps,
  PromoterVerificationSteps,
  Roles,
  UserVerificationSteps
} from "../../../utilities/constants";
import { getCurrentUserSelector, getUserSelector } from "../../../redux/users/selector";
import { patchPromoter } from "../../../redux/referrals/actions";
import useWindowDimensions from "../../../utilities/hooks/useWindowDimensions";
import ProfileViewer from "../ProfileViewer";
import CompanyViewer from "../CompanyViewer";
import PromoterViewer from "../PromoterViewer";

const UserForm = ({ userId, canSkipSteps }) => {
  const [page, setPage] = useState(0);
  const [formDetails, setFormDetails] = useState({
    schema: null,
    steps: null,
  });
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUserSelector, shallowEqual);
  const user = useSelector(userId ? getUserSelector(userId) : getCurrentUserSelector, shallowEqual);
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector((state) => state.profiles.loading);
  const { width } = useWindowDimensions();

  const form = useForm({
    resolver: yupResolver(formDetails.schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const profileData = form.watch();
  const downloadButtonRef = useRef(null);

  useEffect(() => {
    if (currentUser && currentUser.role === Roles.ADMIN.value) {
      setPage(1);
    }
  }, [currentUser]);

  useEffect(() => {
    if (profileData.profilePhoto instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(profileData.profilePhoto);
      reader.onload = () => {
        form.setValue("profilePhoto.fileUrl", reader.result.toString());
      };
    }
  }, [profileData?.profilePhoto])

  const setStepsAndSchema = () => {
    let steps;
    if (!formDetails.schema || !formDetails.steps) {

      // Handle an unauthenticated user i.e. the public resume maker
      if (!user) {
        setFormDetails({
          schema: profileVerificationSchema(user),
          steps: profileVerificationSteps(false),
        });
        return;
      }

      // Handle schema and steps for auth users
      if (user.role === Roles.USER.value) {
        setFormDetails({
          schema: profileVerificationSchema(user),
          steps: profileVerificationSteps(true),
        });
        steps = UserVerificationSteps;
      } else if (user?.role === Roles.COMPANY.value) {
        setFormDetails({
          schema: companyVerificationSchema,
          steps: companyVerificationSteps,
        });
        steps = CompanyVerificationSteps;
      } else {
        setFormDetails({
          schema: promoterVerificationSchema,
          steps: promoterVerificationSteps,
        })
        steps = PromoterVerificationSteps;
      }
      if (user.verificationStatus && steps[user.verificationStatus]) {
        setPage(steps[user.verificationStatus].value);
      } else {
        setPage(1)
      }
      form.setValue("email", user.email);
    }
  }

  useEffect(() => {
    if (userId || user) {
      if (userId && !user) {
        dispatch(getUserById({ id: userId }));
        return;
      }
      let data = null;
      if (user.role === Roles.USER.value) {
        data = user.profile;
      } else if (user.role === Roles.COMPANY.value) {
        data = user.company;
      } else if (user.role === Roles.PROMOTER.value) {
        data = user.promoter;
      }

      if (data) {
        Object.keys(data).forEach((key) => {
          if (data[key] !== null) {
            form.setValue(key, data[key]);
          }
        });
      }

      if (location.pathname.includes("/verification") && (user.isAccountVerified || user.role === Roles.ADMIN.value || (user.role === Roles.COMPANY.value && user.verificationStatus === "PENDING"))) {
        navigate("/dashboard");
      } else if (location.pathname.includes("/profile") && !user.isAccountVerified) {
        navigate("/verification");
        toast.error("You must first finish the verification.", { toastId: "finish-verification-profile-error" });
      }
    }
    setStepsAndSchema()
  }, [userId, user, location.pathname]);

  const getFieldsOfCurrentStep = () => {
    let index = 0;
    return formDetails.steps[page].fields.reduce((acc, step) => {
      if (Array.isArray(step) && formDetails.steps[page]?.multiple) {
        acc.push(formDetails.steps[page]?.multiple[index].name);
        index += 1;
        return acc;
      }
      if (step?.showable) {
        step.fields.forEach((f) => acc.push(f.name));
        return acc;
      }
      acc.push(step.name);
      return acc;
    }, []);
  }

  const handleBackOrNext = async (isBack) => {
    if (page === 0) {
      setPage((oldPage) => oldPage + 1);
      return;
    }

    const names = getFieldsOfCurrentStep();

    const validationResult = await form.trigger(names);
    if (validationResult) {
      const data = { id: user.id };
      const formData = form.getValues();
      Object.keys(formData).forEach((k) => {
        if (names.map((item) => item.includes(k)).some((i) => i) || k === "id" || k === "empty") {
          data[k] = formData[k];
        }
      });

      if (user) {
        let actionCall;
        if (user.role === Roles.USER.value) {
          actionCall = patchProfile;
        } else if (user.role === Roles.COMPANY.value) {
          actionCall = patchCompany;
        } else {
          actionCall = patchPromoter;
        }

        dispatch(actionCall(data))
          .unwrap()
          .then(() => {
            if (!isBack && formDetails.steps.length === page + 1) {
              if (currentUser?.role !== Roles.ADMIN.value) {
                dispatch(getCurrentUser())
                  .unwrap()
                  .then((respData) => {
                    if (respData?.isAccountVerified) {
                      toast.success(!user.isAccountVerified ? "Your account has been verified." : "Your profile has been updated.");
                      navigate("/dashboard");
                    }
                  });
              } else {
                navigate(`/users/${userId}?tab=view-profile`)
              }
            } else if (isBack) {
                setPage((oldPage) => oldPage - 1);
              } else {
                setPage((oldPage) => oldPage + 1);
              }
          });
        return;
      }
      if (isBack) {
        setPage((oldPage) => oldPage - 1);
      } else if (formDetails.steps.length !== page + 1) {
        setPage((oldPage) => oldPage + 1);
      } else {
        downloadButtonRef.current.click();
      }
    }
  };

  const userView = useMemo(() => {
    if (!user || user?.role === Roles.USER.value) {
      return <ProfileViewer user={currentUser} profile={profileData} zoomOut show={width > 1000} downloadButtonRef={downloadButtonRef} />
    }
    if (user?.role === Roles.COMPANY.value) {
      return <CompanyViewer data={profileData} zoomOut />
    }
    if (user?.role === Roles.PROMOTER.value) {
      return <PromoterViewer data={profileData} zoomOut />
    }
    return null;
  }, [user, currentUser, profileData]);

  return (
    <Box width="95%" margin="20px auto">
      <Box>
          {formDetails.steps && (
            <Box>
              <Grid container>
                <Grid item md={8} lg={8} order={{ xs: 2, sm: 2, md: 1, lg: 1, xl: 1 }}>
                  {width < 600 ? (
                    <MobileStepper
                      backButton={
                        <PrimaryButton sx={{ padding: '5px 20px'}} onClick={() => handleBackOrNext(true)}>Back</PrimaryButton>
                      }
                      nextButton={
                        <PrimaryButton onClick={() => handleBackOrNext(false)} loading={isLoading} sx={{ padding: '5px 20px'}}>
                          {(formDetails?.steps || []).length === page + 1 ? (canSkipSteps ? "Update" : "Complete Verification") : "Next"}
                        </ PrimaryButton>
                      }
                      steps={formDetails.steps.length}
                    />
                  ) : (
                    <Stepper activeStep={page - 1} alternativeLabel sx={{ marginBottom: "35px", cursor: canSkipSteps && "pointer" }} steps={6}>
                      {(formDetails?.steps || [])
                        .filter((item) => item.stepNo !== 0)
                        .map((item, index) => (
                          <Step
                            key={item.title}
                            onClick={() => {
                              if (canSkipSteps) {
                                setPage(index + 1);
                              }
                            }}
                            style={{ transform: 'scale(1.2)' }}
                            active={canSkipSteps || page > index}
                          >
                            <StepLabel>{item.title}</StepLabel>
                          </Step>
                        ))}
                    </Stepper >
                  )}
                  <Form
                    steps={formDetails.steps}
                    form={form}
                    startPage={page}
                    options={{
                      showTitle: false,
                      sameLine: true,
                    }}
                    FormButtons={width > 600 &&
                      <Grid container justifyContent="space-between" margin="20px auto">
                        {page !== 0 && (
                          <Grid item xs={12} sm={12} md={6} lg={6} sx={{ marginBottom: '10px'}}>
                            <PrimaryButton onClick={() => handleBackOrNext(true)}>Back</PrimaryButton>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={page !== 0 ? 6 : 12} lg={page !== 0 ? 6 : 12}>
                          <PrimaryButton onClick={() => handleBackOrNext(false)} loading={isLoading}>
                            {(formDetails?.steps || []).length === page + 1 ? (
                              canSkipSteps ? "Update" : (!user ? "Download" : "Complete Verification")
                            ) : "Next"}
                          </PrimaryButton>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item md={4} lg={4} order={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}>
                  {userView}
                </Grid>
              </Grid>
            </Box>
        )}
      </Box>
    </Box>
  );
};

UserForm.propTypes = {
  userId: PropTypes.number,
  canSkipSteps: PropTypes.bool,
};

UserForm.defaultProps = {
  userId: null,
  canSkipSteps: false,
};

export default UserForm;
