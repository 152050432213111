import Login from "../views/public/Login";
import ResetPassword from "../views/public/ResetPassword";
import Register from "../views/public/Register";
import ResetPasswordConfirm from "../views/public/ResetPasswordConfirm";
import SingleJob from "../views/shared/SingleJob";
import EmailVerification from "../views/public/EmailVerification";
import JobsList from "../views/shared/JobList";
import Applications from "../views/private/Applications";
import JobForm from "../components/shared/JobForm";
import Users from "../views/private/Users";
import SingleUser from "../views/private/SingleUser";
import Dashboard from "../views/private/Dashboard";
import UserForm from "../components/shared/ProfileForm";
import UserFormV2 from "../components/shared/ProfileFormV2";
import Documents from "../views/private/Documents";
import Notifications from "../views/private/Notifications";
import { Roles } from "../utilities/constants";
import PasswordChange from "../views/private/PasswordChange";

export const SharedRoutes = [
  {
    path: "/resume",
    view: UserForm,
  },
  {
    path: "/resume/v2/form",
    view: UserFormV2,
  },
  {
    path: "/jobs",
    view: JobsList,
  },
  {
    path: "/jobs/:id",
    view: SingleJob,
  },
];

export const PublicRoutes = [
  {
    path: "/register",
    view: Register,
  },
  {
    path: "/company/register",
    view: Register,
  },
  {
    path: "/login",
    view: Login,
  },
  {
    path: "/email-verification",
    view: EmailVerification,
  },
  {
    path: "/reset-password",
    view: ResetPassword,
  },
  {
    path: "/reset-password/verify",
    view: ResetPasswordConfirm,
  },
];

export const PrivateRoutes = [
  {
    path: "/verification",
    view: UserForm,
  },
  {
    path: "/jobs/form",
    view: JobForm,
    roles: [Roles.ADMIN.value, Roles.COMPANY.value],
  },
  {
    path: "/applications",
    view: Applications,
    roles: [Roles.USER.value],
  },
  {
    path: "/dashboard",
    view: Dashboard,
  },
  {
    path: "/users",
    view: Users,
    roles: [Roles.ADMIN.value],
  },
  {
    path: "/users/:id",
    view: SingleUser,
    roles: [Roles.ADMIN.value, Roles.COMPANY.value, Roles.PROMOTER.value],
  },
  {
    path: "/profile",
    view: UserForm,
    props: {
      canSkipSteps: true,
    },
  },
  {
    path: "/documents",
    view: Documents,
    roles: [Roles.ADMIN.value, Roles.USER.value],
  },
  {
    path: "/notifications",
    view: Notifications,
  },
  {
    path: "/change-password",
    view: PasswordChange
  }
];
