import * as yup from "yup";
import {INDUSTRIES, Roles, languages} from "../../../../utilities/constants";

export const filterFormSchema = yup.object().shape({
  role: yup.string("The role is not valid."),
  email: yup.string("The email is not valid."),
  isAccountVerified: yup.bool("The account verification is not valid.").nullable(),
});

export const filterFormFields = (promoters) => [
  {
    stepNo: 1,
    fullWidth: true,
    fields: [
      {
        name: "profile.firstName",
        fieldId: "firstName",
        label: "First Name",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 2,
      },
      {
        name: "profile.lastName",
        fieldId: "lastName",
        label: "Last Name",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 2,
      },
      {
        name: "email",
        fieldId: "email",
        label: "E-mail",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 3,
      },
      {
        name: "profile.address.country",
        fieldId: "country",
        label: "Country",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 2,
      },
      {
        name: "profile.address.city",
        fieldId: "city",
        label: "City",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 2,
      },
      {
        name: "profile.languages",
        fieldId: "languages",
        label: "Language",
        type: "select",
        isMulti: true,
        values: [
          { label: "Select a language", value: "" },
          ...languages
              .filter(lang => lang.code && lang.name)
              .map(lang => ({ label: lang.name, value: lang.code }))
        ],
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 3,
      },
      {
        name: "profile.workExperience",
        fieldId: "workExperience",
        label: "Work Experience",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 2,
      },
      {
        name: "industry",
        searchableFieldName: "profile.workExperience",
        fieldId: "industry",
        label: "Industry",
        type: "select",
        values: [{ label: "Select an industry", value: "" }, ...INDUSTRIES.asList()],
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 3,
      },
      {
        name: "profile.education",
        fieldId: "education",
        label: "Education",
        type: "input-text",
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 2,
      },
      {
        name: "role",
        fieldId: "role",
        label: "Role",
        type: "select",
        style: {
          padding: "0 10px",
          size: "small",
        },
        values: [{ label: "Select a role", value: "" }, ...Roles.asList()],
        isRequired: false,
        size: 2,
      },
      {
        name: "referral",
        fieldId: "referral",
        label: "Referral",
        type: "select",
        values: [{ value: "", label: "Select a promoter" }, ...promoters.map((i) => ({ value: i.email, label: i.email }))],
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 3,
      },
      {
        name: "isAccountVerified",
        fieldId: "isAccountVerified",
        label: "Account Verified",
        type: "checkbox",
        value: true,
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 2,
      },
      {
        name: "isEmailVerified",
        fieldId: "isEmailVerified",
        label: "Email Verified",
        type: "checkbox",
        value: true,
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 2,
      },
      {
        name: "jobApplicationsCount",
        fieldId: "jobApplicationsCount",
        label: "Applied to jobs?",
        type: "checkbox",
        value: true,
        style: {
          padding: "0 10px",
          size: "small",
        },
        isRequired: false,
        size: 2,
      },
    ],
  },
];
